import { List, Record, Set } from 'immutable';

import FieldErrors from 'shared/records/FieldErrors.jsx';
import MembershipEventCredit from 'shared/records/MembershipEventCredit.jsx';
import MembershipEventDiscount from 'shared/records/MembershipEventDiscount.jsx';
import MembershipCreditPassDiscount from 'shared/records/MembershipCreditPassDiscount.jsx';
import MembershipEventPermission from 'shared/records/MembershipEventPermission.jsx';
import MembershipRetailDiscount from 'shared/records/MembershipRetailDiscount.jsx';

import { merge, isPresent } from 'shared/utils/ObjectUtils.jsx';

export const MEMBERSHIP_TIER_DISCOUNTS_TYPES = {
  EVENT: 'membership_event_discounts',
  RETAIL: 'membership_retail_discounts',
  CREDIT_PASS: 'membership_credit_pass_discounts',
};

class MembershipTier extends Record({
  id: null,
  name: '',
  best_value: false,
  commitment_months: 0,
  interval_months: 0,
  commitment_checked: false,
  join_fee: 0,
  price: 0,
  active_client_count: 0,
  membership_event_discounts: List(),
  membership_credit_pass_discounts: List(),
  membership_retail_discounts: List(),
  membership_all_discounts: List(),
  membership_event_credits: List(),
  membership_event_permissions: List(),
  errors: new FieldErrors(),
}) {
  constructor(object = {}, _options = {}) {
    const errors = new FieldErrors(object.errors);
    const membershipEventDiscounts = object.membership_event_discounts
      ? List(
          object.membership_event_discounts.map(
            med => new MembershipEventDiscount(med)
          )
        )
      : List();

    const membershipCreditPassDiscounts =
      object.membership_credit_pass_discounts
        ? List(
            object.membership_credit_pass_discounts.map(
              mcp => new MembershipCreditPassDiscount(mcp)
            )
          )
        : List();

    const membershipEventCredits = object.membership_event_credits
      ? List(
          object.membership_event_credits.map(
            msc => new MembershipEventCredit(msc)
          )
        )
      : List();

    const membershipRetailDiscounts = object.membership_retail_discounts
      ? List(
          object.membership_retail_discounts.map(
            mrd => new MembershipRetailDiscount(mrd)
          )
        )
      : List();

    const membershipEventPermissions = object.membership_event_permissions
      ? List(
          object.membership_event_permissions.map(
            mep => new MembershipEventPermission(mep)
          )
        )
      : List();

    super(
      merge(object, {
        errors,
        membership_event_discounts: membershipEventDiscounts,
        membership_event_credits: membershipEventCredits,
        membership_retail_discounts: membershipRetailDiscounts,
        membership_event_permissions: membershipEventPermissions,
        membership_credit_pass_discounts: membershipCreditPassDiscounts,
        membership_all_discounts: membershipEventDiscounts
          .concat(membershipCreditPassDiscounts)
          .concat(membershipRetailDiscounts),
      })
    );
  }

  get hasBenefits() {
    return (
      this.membership_event_discounts.size > 0 ||
      this.membership_credit_pass_discounts.size > 0 ||
      this.membership_retail_discounts.size > 0 ||
      this.membership_event_credits.size > 0 ||
      this.membership_event_permissions.size > 0 ||
      this.membership_all_discounts.size > 0
    );
  }

  get eventTypeIds() {
    const evtTypeIds = this.membership_all_discounts
      .reduce(
        (ids, discount) => ids.union(Set(discount.get('event_type_ids', []))),
        Set()
      )
      .union(
        Set(this.membership_event_permissions.map(ep => ep.event_type_id))
      );

    return evtTypeIds;
  }

  get intervalMonths() {
    switch (this.interval_months) {
      case 1:
        return 'month';
      case 3:
        return 'quarter';
      case 12:
        return 'year';
      default:
        return null;
    }
  }

  get commitmentMonths() {
    switch (this.commitment_months) {
      case 1:
        return '1 Month';
      case 3:
        return '3 Months';
      case 6:
        return '6 Months';
      case 12:
        return '1 Year';
      default:
        return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getDiscounts(discounts) {
    return discounts.toJS().map(med => {
      const discount = med;

      delete discount.errors;
      delete discount.subscribable_id;
      delete discount.subscribable_type;
      delete discount.id;

      return discount;
    });
  }

  clone() {
    const clonedTier = this.toJS();
    delete clonedTier.id;
    delete clonedTier.errors;

    clonedTier.name = `${this.name} (copy)`;
    clonedTier.best_value = false;
    clonedTier.membership_event_discounts = this.getDiscounts(
      this.membership_event_discounts
    );
    clonedTier.membership_credit_pass_discounts = this.getDiscounts(
      this.membership_credit_pass_discounts
    );
    clonedTier.membership_retail_discounts = this.getDiscounts(
      this.membership_retail_discounts
    );
    clonedTier.membership_event_credits = this.getDiscounts(
      this.membership_event_credits
    );
    clonedTier.membership_event_permissions = this.getDiscounts(
      this.membership_event_permissions
    );

    return new MembershipTier(clonedTier);
  }

  addEventDiscount(attrs = {}) {
    return this.update(
      attrs?.addToAllList
        ? 'membership_all_discounts'
        : 'membership_event_discounts',
      (list = List()) => list.push(new MembershipEventDiscount(attrs))
    );
  }

  addEventCredit(attrs = {}) {
    return this.update('membership_event_credits', (list = List()) =>
      list.push(new MembershipEventCredit(attrs))
    );
  }

  removeEventCredit({ index, id }) {
    if (index != null) {
      return this.deleteIn(['membership_event_credits', index]);
    }
    const i = this.membership_event_credits.findIndex(d => d.id === id);
    return i >= 0 ? this.deleteIn(['membership_event_credits', i]) : this;
  }

  addExclusiveEventType(eventTypeId) {
    if (
      this.membership_event_permissions.find(
        mep => mep.event_type_id === eventTypeId
      )
    ) {
      return this;
    }

    const newPermission = new MembershipEventPermission({
      event_type_id: eventTypeId,
    });

    return this.update('membership_event_permissions', (list = List()) =>
      list.push(newPermission)
    );
  }

  removeExclusiveEventType(eventTypeId) {
    const index = this.membership_event_permissions.findIndex(
      mep => mep.event_type_id === eventTypeId
    );

    if (index < 0) {
      return this;
    }

    return this.update('membership_event_permissions', (list = List()) =>
      list.remove(index)
    );
  }

  isValid() {
    return (
      this.errors.isEmpty() &&
      this.membership_event_credits.every(mec => mec.isValid()) &&
      this.membership_all_discounts.every(d => d.isValid())
    );
  }

  isFree() {
    return this.price === 0 && this.join_fee === 0;
  }

  validate() {
    let errors = new FieldErrors();

    ['name', 'price', 'interval_months'].forEach(f => {
      if (!isPresent(this[f])) {
        errors = errors.add(f, 'records.errors.required');
      }
    });

    const validatedMeCs = this.membership_event_credits.map(med =>
      med.validate()
    );
    const validatedDiscounts = this.membership_all_discounts.map(med =>
      med.validate()
    );

    return this.merge({
      errors,
      membership_event_credits: validatedMeCs,
      membership_all_discounts: validatedDiscounts,
    });
  }
}

export default MembershipTier;
